import {
  aead,
  generateNewKeysetHandle,
  binaryInsecure,
  hybrid,
  signature,
  macSubtle
} from "tink-crypto";
import { decode32, decode32uint } from "./base32";
import {
  b64_2uint,
  uint2text,
  setKeyID,
  uint2b64,
  text2uint,
  ecoSymmetric,
  undoSafeB64_32,
  WrapperKeyVersions,
  makeSafeB64_32
} from "./utils";

// https://github.com/diafygi/webcrypto-examples/

const GCM_EMPTY_KEY_B64 =
  "CJLI3NYGElQKSAowdHlwZS5nb29nbGVhcGlzLmNvbS9nb29nbGUuY3J5cHRvLnRpbmsuQWVzR2NtS2V5EhIaEAAAAAAAAAAAAAAAAAAAAAAYARABGJLI3NYGIAE=";
const GCM_KEY_OFFSET = 64;
const GCM_KEY_SIZE = 16; //0x10

function __create_AES128_GCM_TinkKeyFromBinary(raw: Uint8Array): Uint8Array {
  // We take a 1.6.1 binary serilized key and replace the RAW key inside.
  // In the future, where tink web will support JSON export, we will use it.
  if (raw.length !== GCM_KEY_SIZE) throw new Error("Key Size Mismatch");
  let emptyKeyBuffer = b64_2uint(GCM_EMPTY_KEY_B64);
  for (let i = 0; i < GCM_KEY_SIZE; i++) {
    emptyKeyBuffer[GCM_KEY_OFFSET + i] = raw[i];
  }
  return emptyKeyBuffer;
}

export const getEncryption = async () => {
  const template = await generateNewKeysetHandle(aead.aes128GcmKeyTemplate());
  const aPriv = await template.getPrimitive(aead.Aead);
  return aPriv;
};

aead.register();
hybrid.register();
signature.register();

async function __importRawGcm128B64Key(b64Key: string) {
  let keyBuffer = b64_2uint(b64Key);
  let templateBuffer = __create_AES128_GCM_TinkKeyFromBinary(keyBuffer);

  let KeySet = await binaryInsecure.deserializeKeyset(templateBuffer);
  let Primitive = await KeySet.getPrimitive(aead.Aead);

  return Primitive;
}

async function __getKeyIDFromEncryption(
  primitive: aead.Aead
): Promise<number[]> {
  let sample = await primitive.encrypt(new Uint8Array([0]));
  return Array.from(sample.subarray(0, 5));
}

export async function decryptGCM2Freetext(b64Key: string, chiper: Uint8Array) {
  if (chiper.length < 5) return "Error: too small";

  let key = await __importRawGcm128B64Key(b64Key);
  let keyID = await __getKeyIDFromEncryption(key);
  setKeyID(chiper, keyID);

  let result = await key.decrypt(chiper);
  let resultTxt = uint2text(result);
  return resultTxt;
}

async function __getPublicECDHKey(
  callback: () => Promise<void>
): Promise<JsonWebKey> {
  let _export = window.crypto.subtle.exportKey;
  let _import = window.crypto.subtle.importKey;
  let _save_result: JsonWebKey[] = [];
  window.crypto.subtle.exportKey = async function (format, key): Promise<any> {
    console.log("export", format, key);
    let result = await _export.bind(window.crypto.subtle)(
      (format as unknown) as any,
      key
    );
    if (result.key_ops?.length === 0 && result.kty === "EC" && !result.d) {
      _save_result.push(result);
    }
    return result;
  };
  // Ignore the fact that we only awaiting jwk in key param.
  // @ts-ignore
  window.crypto.subtle.importKey = async function (
    format,
    key: JsonWebKey,
    algorithm,
    extractable,
    keyUsages
  ): Promise<any> {
    console.log("import", format, key);
    if (key.kty === "EC" && !key.d) {
      _save_result.push(key);
    }
    return await _import.bind(window.crypto.subtle)(
      (format as unknown) as any,
      key,
      algorithm,
      extractable,
      keyUsages
    );
  };
  await callback();
  window.crypto.subtle.exportKey = _export.bind(window.crypto.subtle);
  window.crypto.subtle.importKey = _import.bind(window.crypto.subtle);
  return _save_result[0];
}

export type MyManagerEcies = {
  decrypt: hybrid.HybridDecrypt | null;
  publicJWK: JsonWebKey;
};

export function getHybridTinkKeyId(primitive: any): number[] {
  let keyId = [
    ...primitive.hybridDecryptPrimitiveSet.identifierToPrimitivesMap_.keys()
  ][0];
  return keyId.split(",").map((e: string) => parseInt(e)); // 5 numbers
}

export async function CreateEcies(
  fromBinary?: string
): Promise<MyManagerEcies> {
  let result: hybrid.HybridDecrypt | null = null;
  let jwk = await __getPublicECDHKey(async () => {
    let keyset = !fromBinary
      ? await generateNewKeysetHandle(
          hybrid.eciesP256HkdfHmacSha256Aes128GcmKeyTemplate()
        )
      : await binaryInsecure.deserializeKeyset(b64_2uint(fromBinary));
    result = await keyset.getPrimitive(hybrid.HybridDecrypt);
  });
  return { decrypt: result, publicJWK: jwk };
}

export async function DecryptUsingEciesAnyKey(
  myEcies: MyManagerEcies,
  AsymEncUserKeyB64: string,
  ValueEncB64: string
) {
  let keyID = getHybridTinkKeyId(myEcies.decrypt);
  let bufferENCUserKey = b64_2uint(AsymEncUserKeyB64);
  setKeyID(bufferENCUserKey, keyID);

  let bufferPlainUserKey = await myEcies.decrypt?.decrypt(bufferENCUserKey);
  if (bufferPlainUserKey) {
    let rawUserGCM = bufferPlainUserKey;
    if (rawUserGCM.length === 18) {
      // remove 2 bytes: 0x1a 0x10, TINK specs
      rawUserGCM = rawUserGCM.slice(2);
    }
    let userGCM = await __importRawGcm128B64Key(uint2b64(rawUserGCM));
    let keyIDGCM = await __getKeyIDFromEncryption(userGCM);
    let chiperGCM = b64_2uint(ValueEncB64);
    setKeyID(chiperGCM, keyIDGCM);
    let resultRAW = await userGCM.decrypt(chiperGCM);
    return uint2text(resultRAW);
  } else {
    return "Cant decrypt user key";
  }
}

export async function GetEciesJWK(data: string): Promise<JsonWebKey> {
  let jwk = await __getPublicECDHKey(async () => {
    let keyset = await binaryInsecure.deserializeKeyset(b64_2uint(data));
    await keyset.getPrimitive(hybrid.HybridEncrypt);
  });
  return jwk;
}

export async function GetSignatureJWK(data: string): Promise<JsonWebKey> {
  let jwk = await __getPublicECDHKey(async () => {
    let keyset = await binaryInsecure.deserializeKeyset(b64_2uint(data));
    await keyset.getPrimitive(signature.PublicKeyVerify);
  });
  return jwk;
}

export async function SignDataWithBinaryB64(
  freetext: string,
  tinkKeyBinaryB64: string
) {
  let keyset = await binaryInsecure.deserializeKeyset(
    b64_2uint(tinkKeyBinaryB64)
  );
  let verifyP = await keyset.getPrimitive(signature.PublicKeySign);
  let result = uint2b64(await verifyP.sign(text2uint(freetext)));
  return result;
}

async function _TINK_HKDF_HMAC_SHA25(data: Uint8Array, tagSize: number) {
  let Empty32Buffer = new Uint8Array(new Array(32).fill(0));
  let Single1Byte = new Uint8Array([1]);
  let hmac1 = await macSubtle.hmacFromRawKey("SHA-256", Empty32Buffer, 32);
  let result1 = await hmac1.computeMac(data);
  let hmac2 = await macSubtle.hmacFromRawKey("SHA-256", result1, 32);
  let result2 = await hmac2.computeMac(Single1Byte);
  return result2.slice(0, tagSize);
}

export async function TinkCalcHKDF256FullB64(
  freetextPassword: string
): Promise<string> {
  let hkdf = await _TINK_HKDF_HMAC_SHA25(text2uint(freetextPassword), 32);
  return uint2b64(new Uint8Array(hkdf));
}

export async function ecoSymmeticDecrypt(base64text: string, password: string) {
  let data = b64_2uint(undoSafeB64_32(base64text));
  let passHKDF = await _TINK_HKDF_HMAC_SHA25(text2uint(password), 32);
  let resultArray = await ecoSymmetric(false, data, passHKDF);
  let result = uint2text(resultArray);
  return result;
}

const UnwrapRegex = /(?=\$)(?:\$k([0-9]+)\$([^\$]+)\$?)?(?:\$p([0-9]+)\$([^\$]+)\$?)?/gim;

type UnwrapKV = {
  source: any;
  embedKey: {
    version: any;
    value: string;
  };
  param: {
    version: any;
    value: string;
  };
};

function UnwrapKeyValue(freetext: string): UnwrapKV[] {
  /*
    Example:
    $k21$AAaa09/+=-_.$
    $p30$AAaa09/+=-_.$
    $k33$AAaa09/+=-_.$$p37$AAaa09/+=-_.$
    $p2$AAaa09/+=-_.
  */

  //Reset regex:
  UnwrapRegex.lastIndex = 0;

  //@ts-ignore
  let matches = [...freetext.matchAll(UnwrapRegex)].filter((e) => !!e[0]);
  let enteries = matches.map((e) => ({
    source: e[0],
    embedKey: { version: e[1], value: undoSafeB64_32(e[2]) },
    param: { version: e[3], value: undoSafeB64_32(e[4]) }
  }));

  return enteries;
}

export type QPListStore = Array<{
  version: string;
  keystring: string;
}>;

export function addKeyToQPKeyList(
  store: QPListStore,
  version: string | string[],
  keystring: string
) {
  if (typeof version === "string") {
    store.push({ version, keystring });
  } else {
    version.forEach((v) => store.push({ version: v, keystring }));
  }
  return store;
}

async function tryAll(
  qpKeyStore: QPListStore,
  version: string,
  cb: (key: any) => Promise<string>
): Promise<string> {
  let result = "";

  for (let i = 0; i < qpKeyStore.length; i++) {
    const key = qpKeyStore[i];
    if (key.version != version) continue;
    try {
      result = await cb(key.keystring);
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  throw new Error("Can't decrypt! Or No Key matching type: " + version);
}

function base32_64(item: UnwrapKV): UnwrapKV {
  let newItem = JSON.parse(JSON.stringify(item)) as UnwrapKV;

  const convertBack = (val: string) =>
    uint2b64(decode32uint(undoSafeB64_32(val)));

  if (newItem.embedKey.value) {
    newItem.embedKey.value = convertBack(newItem.embedKey.value);
  }
  if (newItem.param.value) {
    newItem.param.value = convertBack(newItem.param.value);
  }
  return newItem;
}

export async function DecryptAll(freetext: string, qpKeyStore: QPListStore) {
  const enteries = UnwrapKeyValue(freetext);
  let decResults = [];

  for (let i = 0; i < enteries.length; i++) {
    const item = enteries[i];
    let result = item.source;

    const version = item.embedKey.version || item.param.version;
    if (version) {
      try {
        switch (version) {
          case WrapperKeyVersions.SYMMETRIC_SAME_CASE:
            let item2 = base32_64(item);
            result = await tryAll(qpKeyStore, version, async (key) => {
              return await ecoSymmeticDecrypt(item2.param.value, key);
            });
            break;
          case WrapperKeyVersions.SYMMETRIC_SAME_LEN:
            result = await tryAll(qpKeyStore, version, async (key) => {
              return await ecoSymmeticDecrypt(item.param.value, key);
            });
            break;
          case WrapperKeyVersions.ASSYMETRIC_HYBRID_SAME_CASE:
          case WrapperKeyVersions.ASSYMETRIC_HYBRID_NOKEY_SAME_CASE:
            // key = KeyID, Ecies(public, GCM KEY) as uint2b64
            // param = KeyID, GCM Enc(value)
            console.log(1, version, item);
            let item3 = base32_64(item);
            result = await tryAll(qpKeyStore, version, async (key) => {
              let ecies = await CreateEcies(key);

              result = await DecryptUsingEciesAnyKey(
                ecies,
                item3.embedKey.value,
                item3.param.value
              );
              return result;
            });
            break;
          case WrapperKeyVersions.ASSYMETRIC_HYBRID:
          case WrapperKeyVersions.ASSYMETRIC_HYBRID_NOKEY:
            // key = KeyID, Ecies(public, GCM KEY) as uint2b64
            // param = KeyID, GCM Enc(value)
            console.log(2, version, item);
            result = await tryAll(qpKeyStore, version, async (key) => {
              let ecies = await CreateEcies(key);
              result = await DecryptUsingEciesAnyKey(
                ecies,
                item.embedKey.value,
                item.param.value
              );
              return result;
            });
            break;

          case WrapperKeyVersions.POLICY_SIGNATURE:
            break;
          case WrapperKeyVersions.LICENCE_SIGNATURE:
            break;
          default:
            result = `[VER_ERR!] ${item.source}`;
            break;
        }
      } catch (error) {
        console.error(error);
        result = `[ERR!] ${item.source}`;
      }
    }

    decResults.push({ source: item.source, target: result });
  }

  let resulttext = freetext;
  decResults.forEach(
    (e) => (resulttext = resulttext.replace(e.source, e.target))
  );
  return resulttext;
}
