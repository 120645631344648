// to support ie11 add js src to
//    1) IE11PromiseWrapper.js
//    2) msrcrypto.min.js
// from https://github.com/microsoft/MSR-JavaScript-Crypto/tree/master/lib

import {
  EMPTY_KEY_ID,
  text2uint,
  uint2b64,
  b64_2uint,
  ecoSymmetric,
  makeSafeB64_32,
} from "./utils";

function CryptoRandomBuffer(size: number) {
  let array = new Uint8Array(size);
  globalThis.crypto.getRandomValues(array);
  return array;
}

export async function CreateAESGCM128(): Promise<CryptoKey> {
  let key = await globalThis.crypto.subtle.generateKey(
    {
      name: "AES-GCM",
      length: 128,
    },
    true,
    ["encrypt"]
  );
  return key;
}

export async function ImportRawGCMKey(key: string): Promise<CryptoKey> {
  const uintKey = b64_2uint(key);

  const imported = await globalThis.crypto.subtle.importKey(
    "raw",
    uintKey,
    { name: "AES-GCM", length: 128 },
    true,
    ["encrypt", "decrypt"]
  );
  return imported;
}

export async function ExportRawGCMKey(key: CryptoKey): Promise<string> {
  const exported = await globalThis.crypto.subtle.exportKey("raw", key);
  const exportedKeyBuffer = new Uint8Array(exported);
  return uint2b64(exportedKeyBuffer);
}

export async function EncryptText2GcmB64(
  key: CryptoKey,
  freetext: string
): Promise<string> {
  const iv = CryptoRandomBuffer(12);
  const encrypted = await globalThis.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv,
      tagLength: 128,
    },
    key,
    text2uint(freetext)
  );
  const result = new Uint8Array(
    EMPTY_KEY_ID.concat(
      Array.from(iv).concat(Array.from(new Uint8Array(encrypted)))
    )
  );
  return makeSafeB64_32(uint2b64(result));
}

const __fromSafeB64 = (x: string) => x.replace(/-/g, "+").replace(/_/g, "/");

function __pointEncode256(jwk: { x: string; y: string }, fieldSize = 32) {
  /*
    fieldSizeInBytes 
          case CurveType.P256 = 32;
          case CurveType.P384 = 48;
          case CurveType.P521 = 66
    */
  /* 
      example jwk: {
        ...
        x: "3cK_fcj6wOFkRmGFqQpyHekWV2A6togZ9USmNK5kaXc"
        y: "cJj1IHjLzG3R_99Lmrtco1mkUV9sVCTf0HWKuPHoYSc"
      }
      */
  const { x, y } = jwk;
  const result = new Uint8Array(1 + 2 * fieldSize);
  result[0] = 4;
  result.set(b64_2uint(__fromSafeB64(x)), 1);
  result.set(b64_2uint(__fromSafeB64(y)), 1 + fieldSize);
  return result;
}

async function __HMAC_SHA25(
  key: ArrayBuffer | Uint8Array,
  data: ArrayBuffer | Uint8Array,
  tagBytes: number
): Promise<ArrayBuffer> {
  let hmac = await globalThis.crypto.subtle.importKey(
    "raw",
    key,
    {
      name: "HMAC",
      hash: { name: "SHA-256" },
      length: key.byteLength * 8,
    },
    false,
    ["sign"]
  );
  let hashResult = await globalThis.crypto.subtle.sign(
    { name: "HMAC", hash: { name: "SHA-256" } },
    hmac,
    data
  );
  let finalTag = hashResult.slice(0, tagBytes || 32);
  return finalTag;
}

async function __tink256_HKDF(passwordBytes: Uint8Array, tagSize: number) {
  let Empty32Buffer = new Uint8Array(new Array(32).fill(0));
  let Single1Byte = new Uint8Array([1]);
  let HKDF1 = await __HMAC_SHA25(Empty32Buffer, passwordBytes, 32);
  let HKDF2 = await __HMAC_SHA25(HKDF1, Single1Byte, tagSize);
  return HKDF2;
}

export async function CalcHKDF256FullB64(
  freetextPassword: string
): Promise<string> {
  let hkdf = await __tink256_HKDF(text2uint(freetextPassword), 32);
  return uint2b64(new Uint8Array(hkdf));
}

async function __EncryptTextHybridEciesb64(
  publicKey: CryptoKey,
  rawdata: Uint8Array
): Promise<string> {
  let errorMsg = null;
  // Emph =
  let keyPairEmph = await globalThis.crypto.subtle.generateKey(
    {
      name: "ECDH",
      namedCurve: "P-256",
    },
    true,
    ["deriveBits"]
  );
  if (keyPairEmph.publicKey && keyPairEmph.privateKey) {
    let sharedSecret32Bytes = await globalThis.crypto.subtle.deriveBits(
      {
        name: "ECDH",
        // @ts-ignore
        namedCurve: "P-256",
        public: publicKey,
      },
      keyPairEmph.privateKey,
      32 * 8
    );

    let publicEmphJWK = await globalThis.crypto.subtle.exportKey(
      "jwk",
      keyPairEmph.publicKey
    );
    if (publicEmphJWK.x && publicEmphJWK.y) {
      let encodedPublicTempPoint = __pointEncode256({
        x: publicEmphJWK.x,
        y: publicEmphJWK.y,
      });
      let PublicJWKandSharedSec = new Uint8Array(
        Array.from(encodedPublicTempPoint).concat(
          Array.from(new Uint8Array(sharedSecret32Bytes))
        )
      );

      // 16bytes, AES 128
      let HKDF = await __tink256_HKDF(PublicJWKandSharedSec, 16);

      const gcm_iv = CryptoRandomBuffer(12);
      let gcm_key = await globalThis.crypto.subtle.importKey(
        "raw",
        HKDF,
        {
          name: "AES-GCM",
          length: 128,
        },
        true,
        ["encrypt"]
      );
      const gcm_chiper = await globalThis.crypto.subtle.encrypt(
        {
          name: "AES-GCM",
          iv: gcm_iv,
          tagLength: 128,
        },
        gcm_key,
        rawdata
      );
      const finalResult = EMPTY_KEY_ID.concat(
        Array.from(encodedPublicTempPoint)
      )
        .concat(Array.from(gcm_iv))
        .concat(Array.from(new Uint8Array(gcm_chiper)));
      return uint2b64(new Uint8Array(finalResult));
    } else {
      errorMsg = "Can't load JWK x,y";
    }
  } else {
    errorMsg = "Couldn't create ECDH-E";
  }
  return errorMsg;
}

export async function CreateAsymGCM(publicKeyJWK: string): Promise<{
  AsymEncUserKeyB64: string;
  UserGCM: CryptoKey;
  UserGCMKey: string;
}> {
  let publicKey = await globalThis.crypto.subtle.importKey(
    "jwk",
    JSON.parse(publicKeyJWK),
    {
      name: "ECDH",
      namedCurve: "P-256",
    },
    true,
    []
  );
  let UserMasterGCM = await CreateAESGCM128();
  let RawMasterKeyB64 = await ExportRawGCMKey(UserMasterGCM);
  let RawMasterKey = b64_2uint(RawMasterKeyB64);

  let MasterKeyHybridEncryptedB64 = await __EncryptTextHybridEciesb64(
    publicKey,
    RawMasterKey
  );
  return {
    AsymEncUserKeyB64: makeSafeB64_32(MasterKeyHybridEncryptedB64),
    UserGCM: UserMasterGCM,
    UserGCMKey: RawMasterKeyB64,
  };
}

/**
 * Transform an ECDSA signature in DER encoding to IEEE P1363 encoding.
 *
 * @param der the ECDSA signature in DER encoding
 * @param ieeeLength the length of the ECDSA signature in IEEE
 *     encoding. This is usually 2 * size of the elliptic curve field.
 * @return ECDSA signature in IEEE encoding
 */
function __tink_ecdsaDer2Ieee(der: Uint8Array, ieeeLength = 64) {
  const ieee = new Uint8Array(ieeeLength);
  const length = der[1] & 255;
  let offset =
    1 +
    /* 0x30 */
    1;
  /* totalLength */
  if (length >= 128) {
    offset++;
  }
  // Long form length
  offset++;
  // 0x02
  const rLength = der[offset++];
  let extraZero = 0;
  if (der[offset] === 0) {
    extraZero = 1;
  }
  const rOffset = ieeeLength / 2 - rLength + extraZero;
  ieee.set(der.subarray(offset + extraZero, offset + rLength), rOffset);
  offset +=
    rLength +
    /* r byte array */
    1;
  /* 0x02 */
  const sLength = der[offset++];
  extraZero = 0;
  if (der[offset] === 0) {
    extraZero = 1;
  }
  const sOffset = ieeeLength - sLength + extraZero;
  ieee.set(der.subarray(offset + extraZero, offset + sLength), sOffset);
  return ieee;
}

async function __VerifySignECDSA_P256(
  publicKey: CryptoKey,
  dataBytes: Uint8Array,
  TINK_signBytes: Uint8Array
): Promise<boolean> {
  // remove 5 bytes of keyId and convert back to WebCrypto format
  let rawSignResult = __tink_ecdsaDer2Ieee(TINK_signBytes.subarray(5));
  let isValid = await globalThis.crypto.subtle.verify(
    {
      name: "ECDSA",
      hash: { name: "SHA-256" },
    },
    publicKey,
    rawSignResult,
    dataBytes
  );
  return isValid;
}

export async function VerifySign(
  publicKeyJWK: string,
  freetext: string,
  signatureB64: string
): Promise<boolean> {
  let publicKey = await globalThis.crypto.subtle.importKey(
    "jwk",
    JSON.parse(publicKeyJWK),
    {
      name: "ECDSA",
      namedCurve: "P-256",
    },
    true,
    ["verify"]
  );
  let dataBytes = text2uint(freetext);
  let signature = b64_2uint(signatureB64);
  let result = await __VerifySignECDSA_P256(publicKey, dataBytes, signature);
  return result;
}

type WorkerContext = Window &
  typeof globalThis & {
    __hkdf_pass_cache: { [key: string]: Uint8Array };
  };

let selfContext: WorkerContext = globalThis as WorkerContext;

selfContext.__hkdf_pass_cache = {} as { [key: string]: Uint8Array };

export async function ecoSymmeticEncrypt(freetext: string, password: string) {
  if (!selfContext.__hkdf_pass_cache[password]) {
    selfContext.__hkdf_pass_cache[password] = new Uint8Array(
      await __tink256_HKDF(text2uint(password), 32)
    );
  }
  let passHKDF = selfContext.__hkdf_pass_cache[password];

  let data = text2uint(freetext);
  let resultArray = await ecoSymmetric(true, data, passHKDF);
  let result = makeSafeB64_32(uint2b64(resultArray));
  return result;
}

const alwaysShow = (() => {
  let result: { [key: string]: boolean } = {};
  let pairs: Array<[string, boolean]> = " ,.-/$%:?\"='\\\r\t\n"
    .split("")
    .map((e) => [e, true]);
  pairs.forEach((arr) => (result[arr[0]] = arr[1]));
  return result;
})();

export function MaskText(freetext: string, mask: number, keep: number) {
  let chars = (freetext || "").split(""); // utf8, emojis safe;
  let masked = [];
  // text.len != chars.len because JS+UTF8
  for (let i = 0; i < chars.length; i++) {
    if (i % (mask + keep) >= mask || alwaysShow[chars[i]]) {
      masked.push(chars[i]);
    } else {
      masked.push("#");
    }
  }
  return masked.join("");
}
