import "./styles.css";
import "antd/dist/antd.css";

import React from "react";
import { Button, Steps, Input, Space, Typography, Tabs } from "antd";

import { binaryInsecure } from "tink-crypto";
import { b64_2uint } from "./qp-tink-wrapper/utils";

import { SDKUx } from "./SDKUx";
import { MngmtUx } from "./MngmtUx";

const { Step } = Steps;
const { TextArea } = Input;
const { Text } = Typography;
const { TabPane } = Tabs;

async function loadFromBin() {
  const gcmBin =
    "CJLI3NYGElQKSAowdHlwZS5nb29nbGVhcGlzLmNvbS9nb29nbGUuY3J5cHRvLnRpbmsuQWVzR2NtS2V5EhIaEEZRrIAHFK1Pw5QT9PDCvWEYARABGJLI3NYGIAE=";
  debugger;
  let r = await binaryInsecure.deserializeKeyset(b64_2uint(gcmBin));
  return r;
}

export default function App() {
  return (
    <div style={{ margin: "5%" }}>
      <Tabs>
        <TabPane tab="SDK Tools" key="1">
          <SDKUx />
        </TabPane>
        <TabPane tab="Manager Tink Tools" key="2">
          <MngmtUx />
        </TabPane>
      </Tabs>
    </div>
  );
}
