import { Button, Input, Space, Typography, Collapse } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect } from "react";

import {
  b64_2uint,
  uint2text,
  text2uint,
  uint2b64,
  WrapperKeyVersions,
  undoSafeB64_32,
} from "./qp-tink-wrapper/utils";
import {
  CreateEcies,
  decryptGCM2Freetext,
  DecryptUsingEciesAnyKey,
  MyManagerEcies,
  GetEciesJWK,
  GetSignatureJWK,
  SignDataWithBinaryB64,
  TinkCalcHKDF256FullB64,
  ecoSymmeticDecrypt,
  QPListStore,
  addKeyToQPKeyList,
  DecryptAll,
} from "./qp-tink-wrapper/manager";

const { Paragraph, Text } = Typography;
const { Panel } = Collapse;

export function MngmtUx() {
  const [gcmKeyB64, setGcmKeyB64] = React.useState("");
  const [encData, setEncData] = React.useState("");
  const [resultGCM, setResultGCM] = React.useState("");

  const [myEcies, setMyEcies] = React.useState(
    null as unknown as MyManagerEcies
  );
  const [userKey, setUserKey] = React.useState("");
  const [valueEnc, setValueEnc] = React.useState("");
  const [eciesResult, setEciesResult] = React.useState("");

  const [hybridBinary, setHybridBinary] = React.useState("");
  const [hybridJWK, setHybridJWK] = React.useState("");

  const [signBinaryB64, setSignBinaryB64] = React.useState("");
  const [freeTextSign, setFreeTextSign] = React.useState("");
  const [signTinkResult, setSignTinkResult] = React.useState("");

  const [freeTextHKDF, setFreeTextHKDF] = React.useState("");
  const [hkdfPass, setHKDFPAss] = React.useState("");

  const [psuedo, setPsuedo] = React.useState("");
  const [psuedoPass, setPsuedoPass] = React.useState("password");
  const [psuedoResult, setPsuedoResult] = React.useState("");

  const pub_hybrid_b64 =
    "CMS1z90CEtwBCs8BCj10eXBlLmdvb2dsZWFwaXMuY29tL2dvb2dsZS5jcnlwdG8udGluay5FY2llc0FlYWRIa2RmUHVibGljS2V5EosBEkQKBAgCEAMSOhI4CjB0eXBlLmdvb2dsZWFwaXMuY29tL2dvb2dsZS5jcnlwdG8udGluay5BZXNHY21LZXkSAhAQGAEYARohAMqm3JiAEJjXCnrjtIrLsFyeK7PUzajytDQjK8eLyutUIiBZWEjwKysLSnPepLVVd7YdpfOkR0vnyCPqqPgciZ4DEhgDEAEYxLXP3QIgAQ==";
  const pub_hybrid_jwk = JSON.stringify({
    kty: "EC",
    crv: "P-256",
    x: "yqbcmIAQmNcKeuO0isuwXJ4rs9TNqPK0NCMrx4vK61Q",
    y: "WVhI8CsrC0pz3qS1VXe2HaXzpEdL58gj6qj4HImeAxI",
    ext: true,
  });
  const priv_hybrid_b64 =
    "CMS1z90CEoICCvUBCj50eXBlLmdvb2dsZWFwaXMuY29tL2dvb2dsZS5jcnlwdG8udGluay5FY2llc0FlYWRIa2RmUHJpdmF0ZUtleRKwARKLARJECgQIAhADEjoSOAowdHlwZS5nb29nbGVhcGlzLmNvbS9nb29nbGUuY3J5cHRvLnRpbmsuQWVzR2NtS2V5EgIQEBgBGAEaIQDKptyYgBCY1wp647SKy7Bcniuz1M2o8rQ0IyvHi8rrVCIgWVhI8CsrC0pz3qS1VXe2HaXzpEdL58gj6qj4HImeAxIaIEoWky7Nolwt0s6tHUGqcbJol0dwhXOK5d5UkwavJAABGAIQARjEtc/dAiAB";
  const asym_eco_sym = "Pa33ssw0rd!";

  const MyKeyList = [] as QPListStore;
  addKeyToQPKeyList(
    MyKeyList,
    [
      WrapperKeyVersions.SYMMETRIC_SAME_LEN,
      WrapperKeyVersions.SYMMETRIC_SAME_CASE,
    ],
    asym_eco_sym
  );

  addKeyToQPKeyList(
    MyKeyList,
    [
      WrapperKeyVersions.ASSYMETRIC_HYBRID,
      WrapperKeyVersions.ASSYMETRIC_HYBRID_SAME_CASE,
      WrapperKeyVersions.ASSYMETRIC_HYBRID_NOKEY,
      WrapperKeyVersions.ASSYMETRIC_HYBRID_NOKEY_SAME_CASE,
    ],
    priv_hybrid_b64
  );

  const [freeTextDecrypt, setFreeTextDecrypt] = React.useState("");
  const [freeTextDecResult, setFreeTextDecResult] = React.useState("");

  return (
    <>
      <Collapse accordion>
        <Panel header="freetext Decrypt" key="6">
          <Space direction="vertical" style={{ width: "100%" }}>
            Enter freetext:
            <TextArea
              rows={4}
              value={freeTextDecrypt}
              onChange={(e) => setFreeTextDecrypt(e.target.value)}
            />
            <Button
              type="primary"
              onClick={() => {
                DecryptAll(freeTextDecrypt, MyKeyList)
                  .then((e) => setFreeTextDecResult(e))
                  .catch((e) => setFreeTextDecResult(`${e}`));
              }}
            >
              Decrypt
            </Button>
            <TextArea rows={4} value={freeTextDecResult} disabled />
            Using the following keys:
            <ul>
              <li>
                Eco Symmetric password: <br />
                <Text code copyable ellipsis>
                  {asym_eco_sym}
                </Text>{" "}
              </li>
              <li>
                Hybrid:
                <ul>
                  <li>
                    public Base64: <br />
                    <Text code copyable ellipsis>
                      {pub_hybrid_b64}
                    </Text>{" "}
                  </li>
                  <li>
                    public jwk: <br />
                    <Text code copyable ellipsis>
                      {pub_hybrid_jwk}
                    </Text>{" "}
                  </li>
                  <li>
                    🚨 private 🚨 Base64: <br />
                    <Text code copyable ellipsis>
                      {priv_hybrid_b64}
                    </Text>{" "}
                  </li>
                </ul>{" "}
              </li>
            </ul>
          </Space>
        </Panel>
        <Panel header="Sign text with BinaryB64 Key" key="3">
          <Space direction="vertical" style={{ width: "100%" }}>
            <b> Enter Binary of Tink sign key (base64) (Private key) </b>
            <TextArea
              rows={4}
              value={signBinaryB64}
              onChange={(e) =>
                setSignBinaryB64(e.target.value.replace(/[\n\r]/g, ""))
              }
            />
            <b> Enter text to sign: </b>
            <TextArea
              rows={4}
              value={freeTextSign}
              onChange={(e) => setFreeTextSign(e.target.value)}
            />
            <Button
              type="primary"
              onClick={() =>
                SignDataWithBinaryB64(freeTextSign, signBinaryB64)
                  .then((e) => setSignTinkResult(e))
                  .catch((e) => setSignTinkResult(`error: ${e}`))
              }
            >
              Sign text
            </Button>
            <TextArea disabled rows={4} value={signTinkResult} />
          </Space>
        </Panel>
      </Collapse>
      <br />
      <hr />
      <br />
      <Collapse accordion>
        <Panel header="Decrypt B64 with key B64" key="0">
          <Space direction="vertical" style={{ width: "100%" }}>
            <b>Enter GCM Key B64</b>
            <Input
              value={gcmKeyB64}
              onChange={(e) => setGcmKeyB64(e.target.value)}
            />
            <b>Enter encrypted data</b>
            <TextArea
              rows={4}
              value={encData}
              onChange={(e) => setEncData(e.target.value)}
            />
            <Button
              onClick={() => {
                try {
                  decryptGCM2Freetext(gcmKeyB64, b64_2uint(encData))
                    .then((t) => {
                      setResultGCM(t);
                    })
                    .catch((e) => setResultGCM(`Err: ${e}`));
                } catch (error) {
                  setResultGCM(`Err: ${error}`);
                }
              }}
            >
              Result
            </Button>
            <TextArea rows={4} disabled value={resultGCM} />
          </Space>
        </Panel>
        <Panel header="UserKey and ValueEnc" key="1">
          <Space direction="vertical" style={{ width: "100%" }}>
            <Button
              onClick={() => {
                CreateEcies().then((e) => setMyEcies(e));
              }}
            >
              Create Ecies
            </Button>
            <Paragraph copyable={{ text: JSON.stringify(myEcies?.publicJWK) }}>
              <b> JWK public </b>
            </Paragraph>
            <TextArea
              rows={4}
              disabled
              key="jwk"
              value={JSON.stringify(myEcies?.publicJWK)}
            />
            <b> Asym Enc User Key </b>
            <TextArea
              rows={4}
              key="UserKey"
              onChange={(e) => setUserKey(e.target.value)}
              value={userKey}
            />
            <b> Value encrypted </b>
            <TextArea
              rows={4}
              key="ValueEnc"
              onChange={(e) => setValueEnc(e.target.value)}
              value={valueEnc}
            />
            <Space direction="horizontal">
              <Button
                type="primary"
                onClick={async () => {
                  try {
                    let result = await DecryptUsingEciesAnyKey(
                      myEcies,
                      undoSafeB64_32(userKey),
                      undoSafeB64_32(valueEnc)
                    );
                    setEciesResult(result);
                  } catch (error) {
                    setEciesResult(`Err: ${error}`);
                  }
                }}
              >
                Decrypt By Temp Ecies
              </Button>
              <Button
                type="primary"
                onClick={async () => {
                  try {
                    let ecies = await CreateEcies(priv_hybrid_b64);
                    let result = await DecryptUsingEciesAnyKey(
                      ecies,
                      undoSafeB64_32(userKey),
                      undoSafeB64_32(valueEnc)
                    );
                    setEciesResult(result);
                  } catch (error) {
                    setEciesResult(`Err: ${error}`);
                  }
                }}
              >
                Decrypt By Hardcoded
              </Button>
            </Space>
            <b>Result:</b>
            <TextArea disabled rows={4} key="plainText" value={eciesResult} />
          </Space>
        </Panel>
        <Panel header="Get JWK from Binary-Base64 (Public to Public)" key="2">
          <Space direction="vertical" style={{ width: "100%" }}>
            <b>Enter binary bese64 of Hybrid:</b>
            <sub>Template: eciesP256HkdfHmacSha256Aes128GcmKeyTemplate</sub>
            <TextArea
              rows={4}
              key="plainText"
              value={hybridBinary}
              onChange={(e) => setHybridBinary(e.target.value)}
            />

            <>
              <Button
                type="primary"
                onClick={() =>
                  GetEciesJWK(hybridBinary.replace(/[\n\r]/g, ""))
                    .then((e) => setHybridJWK(JSON.stringify(e)))
                    .catch((err) => setHybridJWK(`error: ${err}`))
                }
              >
                Hybrid Public
              </Button>
              <Button
                type="primary"
                onClick={() =>
                  GetSignatureJWK(hybridBinary.replace(/[\n\r]/g, ""))
                    .then((e) => setHybridJWK(JSON.stringify(e)))
                    .catch((err) => setHybridJWK(`error: ${err}`))
                }
              >
                Sign Public
              </Button>
            </>

            <TextArea disabled rows={4} key="plainText" value={hybridJWK} />
          </Space>
        </Panel>

        <Panel header="Generate Long Password (HKDF)" key="4">
          <Space direction="vertical" style={{ width: "100%" }}>
            <TextArea
              rows={4}
              onChange={(e) => setFreeTextHKDF(e.target.value)}
              value={freeTextHKDF}
            />
            <Button
              onClick={() =>
                TinkCalcHKDF256FullB64(freeTextHKDF)
                  .then((e) => setHKDFPAss(e))
                  .catch((e) => setHKDFPAss(`error: ${e}`))
              }
            >
              Create long key to verify
            </Button>
            <TextArea disabled rows={4} value={hkdfPass} />
          </Space>
        </Panel>
        <Panel header="Decrypt Psuedo (Simple Symmetric)" key="5">
          <Space direction="vertical" style={{ width: "100%" }}>
            <Input
              value={psuedoPass}
              onChange={(e) => setPsuedoPass(e.target.value)}
            />
            <TextArea
              rows={4}
              onChange={(e) => setPsuedo(e.target.value)}
              value={psuedo}
            />
            <Button
              onClick={() =>
                ecoSymmeticDecrypt(psuedo, psuedoPass)
                  .then((e) => {
                    setPsuedoResult(e);
                  })
                  .catch((e) => setPsuedoResult(`error: ${e}`))
              }
            >
              Decrypt Psuedo
            </Button>
            <TextArea disabled rows={4} value={psuedoResult} />
          </Space>
        </Panel>
      </Collapse>
    </>
  );
}
