function _ab2str(buf: ArrayBuffer): string {
  return String.fromCharCode.apply(null, Array.from(new Uint8Array(buf)));
}
function _str2ab(str: string): ArrayBuffer {
  var buf = new ArrayBuffer(str.length);
  var bufView = new Uint8Array(buf); // Uint8 = 1 byte per char, Uint16 = 2byte/char
  for (var i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

export function text2uint(str: string): Uint8Array {
  return new Uint8Array(_str2ab(unescape(encodeURIComponent(str))));
}

export function uint2text(bin: ArrayBuffer): string {
  return decodeURIComponent(escape(_ab2str(bin)));
}

export function uint2b64(bin: ArrayBuffer): string {
  return btoa(_ab2str(bin));
}

export function b64_2uint(str: string): Uint8Array {
  return new Uint8Array(_str2ab(atob(str)));
}

export const EMPTY_KEY_ID = [0, 0, 0, 0, 0];

export function setKeyID(
  buffer: Uint8Array | null,
  keyIDBuffer = EMPTY_KEY_ID
) {
  if (!buffer || buffer.length < 5 || !keyIDBuffer || keyIDBuffer.length < 5) {
    return new Uint8Array([-7]);
  }

  for (let i = 0; i < 5; i++) {
    buffer[i] = keyIDBuffer[i];
  }
}

function eco_sym_enc(value: Uint8Array, hkdf: Uint8Array): Uint8Array {
  let hlen = hkdf.length;
  let vlen = value.length;
  let salt = vlen;

  let result = new Uint8Array(vlen);

  result[0] = (salt + 0 + value[0] + hkdf[(0 + salt) % hlen]) % 256;
  for (let i = 1; i < vlen; i++) {
    result[i] =
      (value[i] +
        salt +
        (result[i - 1] ^ hkdf[(i + salt) % hlen]) *
          (result[i - 1] ^ hkdf[(i + salt) % hlen])) %
      256;
  }

  return result;
}

function eco_sym_dec(value: Uint8Array, hkdf: Uint8Array): Uint8Array {
  let hlen = hkdf.length;
  let vlen = value.length;
  let salt = vlen;

  let result = new Uint8Array(vlen);

  for (let i = vlen - 1; i > 0; i--) {
    result[i] =
      (value[i] -
        salt -
        (value[i - 1] ^ hkdf[(i + salt) % hlen]) *
          (value[i - 1] ^ hkdf[(i + salt) % hlen]) +
        256 +
        256 * 256) %
      256;
  }
  result[0] = (value[0] - salt - 0 - hkdf[(0 + salt) % hlen] + 256 * 3) % 256;

  return result;
}

const ecoStepsEncrypt: Array<
  (prev: Uint8Array, pass: Uint8Array) => Uint8Array
> = [
  (prev, pass) => eco_sym_enc(prev, pass),
  (prev, pass) => eco_sym_enc(prev.reverse(), pass),
  (prev, pass) => eco_sym_enc(prev, pass),
  (prev, pass) => eco_sym_enc(prev.reverse(), pass),
];

const ecoStepsDecrypt: Array<
  (prev: Uint8Array, pass: Uint8Array) => Uint8Array
> = [
  (prev, pass) => eco_sym_dec(prev, pass).reverse(),
  (prev, pass) => eco_sym_dec(prev, pass),
  (prev, pass) => eco_sym_dec(prev, pass).reverse(),
  (prev, pass) => eco_sym_dec(prev, pass),
];

export async function ecoSymmetric(
  encrypt: boolean,
  value: Uint8Array,
  password: Uint8Array
) {
  const steps = encrypt ? ecoStepsEncrypt : ecoStepsDecrypt;
  let data = value;
  for (let i = 0; i < steps.length; i++) {
    data = steps[i](data, password);
  }
  return data;
}

const safeB64Pairs: [string, RegExp][][] = [
  // Including premaid regexes
  [
    ["+", /\+/g],
    ["-", /-/g],
  ],
  [
    ["/", /\//g],
    ["_", /_/g],
  ],
  [
    ["=", /=/g],
    [".", /\./g],
  ],
];

export function makeSafeB64_32(b64string: string) {
  let result = b64string || "";
  safeB64Pairs.forEach((p) => {
    result = result.replace(p[0][1], p[1][0]);
  });
  return result;
}
export function undoSafeB64_32(b64string: string) {
  let result = b64string || "";
  safeB64Pairs.forEach((p) => {
    result = result.replace(p[1][1], p[0][0]);
  });
  return result;
}

export const WrapperKeyVersions = {
  // DEPRECATED:
  //    XOR_WRAPPED: "2" // ex: $p2$AAAA== (no ending $)

  SYMMETRIC_SAME_LEN: "30",
  SYMMETRIC_SAME_CASE: "31",
  // server: freetext password
  // client: same

  ASSYMETRIC_HYBRID: "40",
  ASSYMETRIC_HYBRID_SAME_CASE: "41",
  // server: binary base64 Ecies (private)
  // mobile: same (public)
  // web : jwk (public)

  ASSYMETRIC_HYBRID_NOKEY: "42",
  ASSYMETRIC_HYBRID_NOKEY_SAME_CASE: "43",
  // Same Hybrid - Ecies

  POLICY_SIGNATURE: "50",
  // Same Hybrid - bu ECDSA

  LICENCE_SIGNATURE: "60",
  // Same Hybrid - bu ECDSA
};

export function WrapKeyValue(
  type: string,
  keyValue?: string,
  paramValue?: string
): string {
  let wrappedKey = "";
  let wrappedParam = "";
  if (keyValue) {
    wrappedKey = "$k" + type + "$" + keyValue + "$";
  }
  if (paramValue) {
    wrappedParam = "$p" + type + "$" + paramValue + "$";
  }

  let result = wrappedKey + wrappedParam;
  return result;
}
