import { Space, Typography, Collapse, Button, Input, InputNumber } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect } from "react";

import {
  CreateAESGCM128,
  CreateAsymGCM,
  EncryptText2GcmB64,
  ExportRawGCMKey,
  VerifySign,
  CalcHKDF256FullB64,
  ecoSymmeticEncrypt,
  MaskText
} from "./qp-tink-wrapper/sdk";
import {
  b64_2uint,
  undoSafeB64_32,
  WrapKeyValue,
  WrapperKeyVersions
} from "./qp-tink-wrapper/utils";
import {
  encode32,
  decode32,
  WrapKeyValue64_base32
} from "./qp-tink-wrapper/base32";

const { Panel } = Collapse;
const { Paragraph, Text } = Typography;

const uint2Hex = (t: Uint8Array) =>
  Array.from(t)
    .map((e) => e.toString(16))
    .join(" | ");

export function SDKUx() {
  const [freeText, setFreeText] = React.useState("");
  const [resultGCM, setResultGCM] = React.useState("");
  const [myKey, setMyKey] = React.useState((null as unknown) as CryptoKey);
  const [myKeyRaw, setMyKeyRaw] = React.useState("");
  //-------------------
  const [pubHybridJWK, setPubHybridJWK] = React.useState("");
  const [userKey, setUserKey] = React.useState("");
  const [valueEnc, setValueEnc] = React.useState("");
  //-------------------
  const [signJWK, setSignJWK] = React.useState("");
  const [signBase64, setSignB64] = React.useState("");
  const [isValidSign, setValidSign] = React.useState("");
  //-------------------
  const [hkdfPass, setHKDFPAss] = React.useState("");
  //-------------------
  const [psuedoPass, setPsudoPass] = React.useState("password");
  const [psuedo, setPsudo] = React.useState("");
  const [psuedoHex, setPsudoHex] = React.useState("");
  //--------------------
  const [mask, setMask] = React.useState("");
  const [maskLen, setMaskLen] = React.useState(0);
  const [keepLen, setKeepLen] = React.useState(0);
  //--------------------
  const [base32, setBase32] = React.useState("");
  const [base32Decode, setBase32Decode] = React.useState("");

  useEffect(() => {
    CreateAESGCM128().then((e) => {
      setMyKey(e);
      ExportRawGCMKey(e).then((k) => setMyKeyRaw(k));
    });
  }, []);

  return (
    <>
      <b>Enter free-text: </b>
      <TextArea
        value={freeText}
        onChange={(e) => {
          let txt = e.target.value;
          setFreeText(txt);
        }}
        rows={4}
      />
      <Space direction="vertical" style={{ width: "100%" }}>
        <Collapse accordion>
          <Panel header="Same-Case (Base32) Encode\Decode" key="a">
            <Space direction="vertical" style={{ width: "100%" }}>
              <Button onClick={() => setBase32(encode32(freeText))}>
                Encode Base32
              </Button>
              <TextArea
                rows={4}
                value={base32}
                onChange={(e) => setBase32(e.target.value)}
              />

              <Button onClick={() => setBase32Decode(decode32(base32))}>
                Re-Decode Base32
              </Button>
              <TextArea disabled rows={4} value={base32Decode} />
            </Space>
          </Panel>
          <Panel header="GCM Freetext" key="0">
            <Space direction="vertical" style={{ width: "100%" }}>
              <Button
                onClick={() =>
                  EncryptText2GcmB64(myKey, freeText).then((e) =>
                    setResultGCM(e)
                  )
                }
              >
                Encrypt freetext
              </Button>
              <Paragraph copyable={{ text: myKeyRaw }}>
                <b>GCM Key</b>
                <Text code>{myKeyRaw}</Text>
              </Paragraph>

              <Paragraph copyable={{ text: resultGCM }}>
                Encrypt result
              </Paragraph>
              <TextArea disabled rows={4} value={resultGCM} />
            </Space>
          </Panel>
          <Panel header="Assymetric Hybrid (Ecies + GCM Encrypt)" key="1">
            <Space direction="vertical" style={{ width: "100%" }}>
              <b>Public Key JWK</b>
              <TextArea
                rows={4}
                key="publicJWK"
                onChange={(e) => setPubHybridJWK(e.target.value)}
                value={pubHybridJWK}
              />
              <Button
                type="primary"
                onClick={async () => {
                  let result = await CreateAsymGCM(pubHybridJWK);
                  setUserKey(result.AsymEncUserKeyB64);
                  let chiper = await EncryptText2GcmB64(
                    result.UserGCM,
                    freeText
                  );
                  setValueEnc(chiper);
                }}
              >
                Asym UserKey + Encrypted FreeText
              </Button>
              <TextArea
                disabled
                rows={4}
                key="UserKey"
                value={
                  WrapKeyValue(
                    WrapperKeyVersions.ASSYMETRIC_HYBRID_NOKEY,
                    userKey,
                    valueEnc
                  ) +
                  "\n\n" +
                  WrapKeyValue64_base32(
                    WrapperKeyVersions.ASSYMETRIC_HYBRID_NOKEY_SAME_CASE,
                    userKey,
                    valueEnc
                  )
                }
              />
            </Space>
          </Panel>
          <Panel header="Verify Signature" key="2">
            <Space direction="vertical" style={{ width: "100%" }}>
              <b> Enter public verify JWK </b>
              <TextArea
                value={signJWK}
                onChange={(e) => setSignJWK(e.target.value)}
              />
              <b> Enter signature in Base64 </b>
              <TextArea
                value={signBase64}
                onChange={(e) => setSignB64(e.target.value)}
              />
              <Button
                type="primary"
                onClick={() => {
                  VerifySign(signJWK, freeText, signBase64)
                    .then((e) => {
                      setValidSign(`${e}`);
                    })
                    .catch((e) => {
                      setValidSign(`error ${e}`);
                    });
                }}
              >
                Verify
              </Button>
              <span>
                Is Valid? <b>{isValidSign}</b>{" "}
              </span>
            </Space>
          </Panel>
          <Panel header="Create Long Password" key="3">
            <Space direction="vertical" style={{ width: "100%" }}>
              <Button
                onClick={() =>
                  CalcHKDF256FullB64(freeText)
                    .then((e) => setHKDFPAss(e))
                    .catch((e) => setHKDFPAss(`error: ${e}`))
                }
              >
                Create long key
              </Button>
              <TextArea disabled rows={4} value={hkdfPass} />
            </Space>
          </Panel>
          <Panel header="Psuedo " key="4">
            <Space direction="vertical" style={{ width: "100%" }}>
              <Input
                value={psuedoPass}
                onChange={(e) => setPsudoPass(e.target.value)}
              />
              <Button
                onClick={() =>
                  ecoSymmeticEncrypt(freeText, psuedoPass)
                    .then((e) => {
                      setPsudo(e);
                      setPsudoHex(uint2Hex(b64_2uint(undoSafeB64_32(e))));
                    })
                    .catch((e) => setPsudo(`error: ${e}`))
                }
              >
                Psuedo
              </Button>
              <TextArea
                disabled
                rows={4}
                value={
                  WrapKeyValue(
                    WrapperKeyVersions.SYMMETRIC_SAME_LEN,
                    "",
                    psuedo
                  ) +
                  "\n" +
                  WrapKeyValue64_base32(
                    WrapperKeyVersions.SYMMETRIC_SAME_CASE,
                    "",
                    psuedo
                  )
                }
              />
              <TextArea disabled rows={4} value={psuedoHex} />
            </Space>
          </Panel>
          <Panel header="Mask" key="5">
            <Space direction="vertical" style={{ width: "100%" }}>
              <Space direction="horizontal">
                Mask:{" "}
                <InputNumber
                  min={0}
                  value={maskLen}
                  onChange={(e) => setMaskLen(e)}
                />
                Keep:{" "}
                <InputNumber
                  min={0}
                  value={keepLen}
                  onChange={(e) => setKeepLen(e)}
                />
                <Button
                  onClick={() => setMask(MaskText(freeText, maskLen, keepLen))}
                >
                  Mask!
                </Button>
              </Space>
              <TextArea dir="auto" disabled rows={4} value={mask} />
            </Space>
          </Panel>
        </Collapse>
      </Space>
    </>
  );
}
